import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "shell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_property_bread_crumb = _resolveComponent("property-bread-crumb")
  const _component_secondary_nav = _resolveComponent("secondary-nav")
  const _component_pin = _resolveComponent("pin")
  const _component_rf_card = _resolveComponent("rf-card")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_property_bread_crumb, { headers: _ctx.breadHeaders }, null, 8, ["headers"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_secondary_nav, {
        navs: _ctx.navs,
        active: _ctx.activeNavItem,
        onChoose: _ctx.changePath
      }, null, 8, ["navs", "active", "onChoose"]),
      (_ctx.type==='pin')
        ? (_openBlock(), _createBlock(_component_pin, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.type==='rfCard')
        ? (_openBlock(), _createBlock(_component_rf_card, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}