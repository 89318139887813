import { computed, ref, Ref } from 'vue';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import {
    getSearch, ListHeaderItem, RequestData,
    SearchKey
} from '@/components/common/list';

const searchKeyListForEncrypt: SearchKey = [
    {
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }
];

const searchKeyListNormal: SearchKey = [
    {
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'Key',
        label: WordList.TmpKeyListTanleKey
    }
];

const headers: Array<ListHeaderItem> = [
    {
        label: WordList.TabelPersonUserInHtmlName,
        name: 'Name'
    }, {
        label: WordList.TmpKeyListTanleKey,
        name: 'Code'
    }, {
        label: WordList.ProperAllTextRepeats,
        name: 'Repeats'
    }, {
        label: WordList.TabelUpdateBoxDevice,
        name: 'DevicesText'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];
const initPin = () => {
    // list请求借口及请求参数
    const requestData: Ref<RequestData> = ref({
        url: 'v3/web/community/delivery/getDeliverPinListForOld',
        param: getSearch({
            searchKey: 'Name',
            searchValue: ''
        })
    });

    // list搜索查询处理
    const updateList = ref(0);
    const saveParamInPath = ref(false);
    const searchList = () => {
        updateList.value += 1;
        saveParamInPath.value = true;
    };

    const add = () => {
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldAddDeliveryAuth}?type=pin&opera=add`);
    };

    const edit = (val: object) => {
        localStorage.setItem('modifyKey', '1');
        localStorage.setItem('propertyKeyDetail', JSON.stringify(val));
        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldAddDeliveryAuth}?type=pin&opera=edit`);
    };

    return {
        requestData,
        updateList,
        saveParamInPath,
        searchList,
        add,
        edit,
        headers,
        searchKeyListNormal,
        searchKeyListForEncrypt
    };
};
export default initPin;
