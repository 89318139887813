import { computed } from 'vue';
import { DeliveryAuthType } from '@/views/pm/old-community/delivery-auth/all.type';
import router, { propertyBaseRouter } from '@/router';

// 导航
const breadHeaders = [{ label: WordList.DeliveryDeliveryAuth }];

// 标签卡数据
const navs = [
    {
        label: WordList.ProperAllTextPIN,
        key: 'pin',
        path: `/${propertyBaseRouter}/oldDeliveryAuth?type=pin`
    }, {
        label: WordList.DeliveryRfCard,
        key: 'rfCard',
        path: `/${propertyBaseRouter}/oldDeliveryAuth?type=rfCard`
    }
];

const initData = (type: DeliveryAuthType) => {
    // 激活的标签
    const activeNavItem = computed(() => type);

    // 标签切换操作
    const changePath = (key: string, path: string) => {
        router.push(path);
    };

    return {
        activeNavItem,
        breadHeaders,
        changePath,
        navs
    };
};

export default initData;