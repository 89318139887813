
import { defineComponent, watch, ref } from 'vue';
import { listPagination } from '@/components/common/list';
import AddButton from '@/components/common/add-button/index.vue';
import initPin from '@/views/pm/old-community/delivery-auth/module/init-pin';
import { community } from '@/data';

export default defineComponent({
    components: {
        listPagination,
        AddButton
    },
    setup() {
        const {
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            headers,
            add,
            edit,
            searchKeyListNormal,
            searchKeyListForEncrypt
        } = initPin();

        const searchKeyList = ref(searchKeyListNormal);
        watch(community.IsEncryptPin, () => {
            if (community.IsEncryptPin.value === '1') {
                searchKeyList.value = searchKeyListForEncrypt;
            } else {
                searchKeyList.value = searchKeyListNormal;
            }
        }, {
            immediate: true
        });
        return {
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            headers,
            add,
            edit,
            searchKeyList
        };
    }
});
