
import { defineComponent, PropType } from 'vue';
import Pin from '@/views/pm/old-community/delivery-auth/module/pin.vue';
import RfCard from '@/views/pm/old-community/delivery-auth/module/rf-card.vue';
import SecondaryNav from '@/components/common/secondary-nav/index.vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { DeliveryAuthType } from '@/views/pm/old-community/delivery-auth/all.type';
import initData from '@/views/pm/old-community/delivery-auth/init-data';

export default defineComponent({
    components: {
        SecondaryNav,
        PropertyBreadCrumb,
        Pin,
        RfCard
    },
    props: {
        type: {
            type: String as PropType<DeliveryAuthType>,
            default: 'pin'
        }
    },
    setup(props) {
        const propsType = props.type;
        const {
            breadHeaders,
            activeNavItem,
            navs,
            changePath
        } = initData(propsType);

        return {
            breadHeaders,
            activeNavItem,
            navs,
            changePath
        };
    }
});
